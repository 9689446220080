<template>
  <div class="display-flex paignation-mobile">
      <div @click="handlePrev()" class="paignation-mobile__btn" :class="{'inactive' : page.currentNumber == 1}"> &lt; </div>
      <div class="paignation-mobile__number">
          <span class="mr-03rem">{{page.currentNumber}}</span>
          <span class="mr-03rem">{{$t('OF')}}</span>
          <span>{{page.totalPages}}</span>

        </div>
      <div @click="handleNext()"  class="paignation-mobile__btn"  :class="{'inactive' : page.currentNumber == page.totalPages}"> &gt; </div>
  </div>
</template>

<script>
export default {
    props: {
        params: {
            type: Object,
        }
    },
    data() {
        return {
            page: {
                currentNumber: 1,
                totalPages: 1,
                pageSize: 5,
            }, 
            currentNumber: 1, 
            showUpto: 3,
            showFromto: 0,
        }
    },
    watch: {
        params: {
            deep: true,
            handler(prev, nesw) {
                this.init();  
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {

        init() {
            this.page.totalPages = this.params.totalPages; 
        },

        handlePrev() {
        //     if (this.page.currentNumber > 1) {
        //         this.page.currentNumber--;
        //     } 
            
        //    this.$emit('changeNextPrevEvent', this.page.currentNumber);
           if (this.page.currentNumber != 1){
                this.showFromto = ((this.page.currentNumber - 2) * this.page.pageSize) ;
                this.page.currentNumber =  this.page.currentNumber - 1;
                this.showUpto = (this.page.currentNumber * this.page.pageSize); 
            } 
            this.params.latestPredictionList = this.params.latestPredictionListResult.slice(this.showFromto, this.showUpto);
        },

        handleNext() {
        //    if (this.page.currentNumber < this.page.totalPages) {
        //         this.page.currentNumber++;
        //     }  
        //     this.$emit('changeNextPrevEvent', this.page.currentNumber); 
            if (this.page.currentNumber != this.page.totalPages){
                this.showFromto = (this.page.currentNumber * this.page.pageSize) ;
                this.page.currentNumber =  this.page.currentNumber + 1;
                this.showUpto = (this.page.currentNumber * this.page.pageSize); 
                this.params.latestPredictionList = this.params.latestPredictionListResult.slice(this.showFromto, this.showUpto); 
            }  
            
        }
    },
}
</script>

<style>
.paignation-mobile{
    background-color: var(--color-grey-60);
    border-radius: 8px;
    padding: 0.5rem 0.563rem;
    font-size: 0.688rem;
}
.paignation-mobile__btn{
    /* border: .1rem solid grey; */
    padding: 0 .5rem;
    cursor: pointer;
}
.paignation-mobile__number{
    padding: 0 .5rem;
    border-left: .1rem solid grey;
    border-right: .1rem solid grey;
}
.inactive{
 color: grey;
}
</style>