<template>
    <div :class="className" @click="handleClick()">
        <div class="tick-wrapper selected" v-if="isSelect" ></div>
        <div class="tick-wrapper" v-else :class="{selected: isSelectSelf}" ></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        className: {
            type: String
        },
        isSelect: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isSelect() {
         this.setIsSelectData();
        }
    },
    data() {
        return {
            isSelectSelf: false
        }
    },
    mounted() {
        this.setIsSelectData();
    },
    methods: {
        setIsSelectData() {
            this.isSelectSelf = this.isSelect
        },

        handleClick() {        
            this.isSelectSelf = !this.isSelectSelf;
            this.$emit('changeTickEvent',this.isSelectSelf);
        }
    },
}
</script>

<style scoped>
.tick-wrapper{
    cursor: pointer;
    width:1.1rem;
    height:1.1rem;
    margin-right:.5rem;
    background: url('../../../static/images/icons/icon_tick_white_unselect.png') center/cover no-repeat;
}
.tick-wrapper.selected{
    background: url('../../../static/images/icons/icon_tick_white_selected.png') center/cover no-repeat;
}
</style>