<template>
    <ModalPopup ref="modalPopupRef" :isFullScreen = true>
       <SignIn v-show="popUpSignInModule === 'signIn'" :isPopUp=true @loginSucuessEvent="loginSucuessEvent" ></SignIn>
       <SignUp v-show="popUpSignInModule === 'signUp'" :isPopUp=true @loginSucuessEvent="loginSucuessEvent" ></SignUp>
       <ForgetPassword v-show="popUpSignInModule === 'forgetPassword'" :isPopUp=true @loginSucuessEvent="loginSucuessEvent"></ForgetPassword>
    </ModalPopup>
</template>

<script>
import ModalPopup from '@/components/modal/ModalPopup.vue'

import SignIn from '@/components/user/SignIn.vue'
import SignUp from '@/components/user/SignUp.vue'
import ForgetPassword from '@/components/user/ForgetPassword.vue'

import {mapGetters,mapActions} from 'vuex'
export default {

    components:{
        ModalPopup,
        SignIn,
        SignUp,
        ForgetPassword
    },

    data() {
        return {
            isShowSignInModal: true,
        }
    },

    computed: {
        ...mapGetters([              
                'popUpSignInModule',
           ]),
    },
    methods: {
        handleOpen() {
             this.$refs.modalPopupRef.handleOpen();
        },

        handleClose() {
             this.$refs.modalPopupRef.handleClose();
        },

        loginSucuessEvent(data) {
             this.$emit('loginSucuessEvent',data);
        },
    },
}
</script>

<style>

</style>