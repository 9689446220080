<template>
  <div
    v-if="isShow"
    class="modal-wrapper  display-flex-center flex-col"
    @click="handleClose"
  >
    <transition name="slideUp">
      <div
        class="modal-container"
        @click="handleContent($event)"
        v-if="isShowContent"
        :class="[{ 'modal-container__fullscreen': isFullScreen }, modalSize]"
      >
        <div class="modal-header">
          <div class="pointer" @click="handleClose">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.6663 2.68301L21.3163 0.333008L11.9997 9.64968L2.68301 0.333008L0.333008 2.68301L9.64968 11.9997L0.333008 21.3163L2.68301 23.6663L11.9997 14.3497L21.3163 23.6663L23.6663 21.3163L14.3497 11.9997L23.6663 2.68301Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    //options : large
    modalSize: {
      type: String,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      isShowContent: false,
    };
  },
  created() {},
  beforeUnmount() {
    document.body.classList.remove("hidden");
  },
  mounted() {
    window.addEventListener("keydown", this.listenKeypressEvent);
  },
  methods: {
    handleOpen() {
      document.body.className = "hidden";
      this.showModal();
    },
    handleClose() {
      this.$emit("handleCloseEvent");
      document.body.classList.remove("hidden");
      this.hideModal();
    },
    showModal() {
      this.isShow = true;
      setTimeout(() => (this.isShowContent = true), 500);
    },
    hideModal() {
      this.isShowContent = false;
      setTimeout(() => (this.isShow = false), 500);
    },
    listenKeypressEvent(e) {
      if (e.key === "Escape") {
        this.handleClose();
      }
    },
    handleContent(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style>
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 57%);
  z-index: 10;
}
.modal-container {
  display: flex;
  width: 37.5rem;
  background-color: var(--color-grey-40);
  border-radius: 0.5rem;
  overflow: hidden;
  /* max-height: 25rem; */
  max-width: 50rem;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}
.modal-container__fullscreen {
  max-width: 75rem;
  padding: 0;
  position: relative;
  width: auto;
}
.modal-container__fullscreen .modal-header {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}
.modal-body {
  height: calc(100% - 2rem);
}

.term-body span {
  color: var(--color-grey-light) !important;
}
.modal-container.large {
  width: 50rem;
}
.modal-confirmation__body {
  margin: 2.75rem 5rem;
}
@media (max-width: 768px) {
  .modal-container {
    width: 90%;
  }
  .modal-confirmation__body {
    margin: 2rem 0;
  }
}
</style>
