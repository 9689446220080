<template>
    <!-- social media share -->
    <!-- <div class="prediction-social-media__wrapper">
        <div class="prediction-social-media__icon-share" @click="handleSocialMedia('FB')">
            <div class="mr-05rem display-flex">
                <SVGIcon :className="'svg-icon'" :name="'FB'"></SVGIcon>
            </div>
            <span class="mr-05rem">{{$t('SHARE')}}</span><span>1K</span>
        </div>
        <a :href="o.url" class="prediction-social-media__icon-wrapper" target="_blank" v-for="(o,index) in socialMediaList" :key="index">
            <div class="tooltips-wrapper">
                <div class="tooltips-triangle"></div>
                <div class="tooltips-content">{{$t(o.displayName)}} </div>                      
            </div>
            <SVGIcon :className="'svg-icon'" :name="o.iconName"></SVGIcon>
        </a>
    </div> -->

    <!-- agree term and conditions -->
    <TickIcon :className="'display-flex-center mb-1rem pointer'" @changeTickEvent="changeTickEvent($event)" :isSelect="isTick">
        <slot><span class="prediction-agree__title">{{$t('I_HAVE_DONE_THE_ACTION_AND_AGREE_TERM_CONDITION')}}</span></slot>
    </TickIcon>
</template>

<script>
import SVGIcon from '@/components/indicator/SVGIcon.vue'
import TickIcon from '@/components/indicator/Tick.vue'

export default {
    components: {
        SVGIcon,
        TickIcon
    },
    props: {
        isTick: {
            type: String
        }
    },

    data() {
        return {        
            socialMediaList: [
                {
                    iconName: 'FB',
                    displayName: 'FOLLOW_FB_PAGE',
                    url: 'https://www.facebook.com/asiasport.thai'
                },
                {
                    iconName: 'IG',
                    displayName: 'FOLLOW_IG_PAGE',
                    url: 'https://www.instagram.com/asiasport.thai/'
                },
                {
                    iconName: 'LINE',
                    displayName: 'FOLLOW_LINE_PAGE',
                    url: 'https://line.me/R/ti/p/@asiasport.thai'
                },  
                {
                    iconName: 'TikTok',
                    displayName: 'FOLLOW_TIKTOK_PAGE',
                    url: 'https://www.tiktok.com/@asiasport.thai?lang=en'
                }
            ],
        }
    },
    methods: {
        handleSocialMedia(o) {
            let socialMediaUrl = "https://www.facebook.com/sharer/sharer.php?u=";
            let href = window.location.href
            let url = socialMediaUrl + href;
        
            let windowOptions = "location=yes,height=570,width=420,scrollbars=yes,status=yes";

            //window.open(url,'_blank', windowOptions);

            this.$tools.popupwindow(url,'', 550, 570);
        },
        changeTickEvent(result) {
             this.$emit('changeTickEvent2',result);
        }
    },
}
</script>

<style>

/* social media */
.prediction-social-media__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
}
.prediction-social-media__icon-share{
    /* background-color: var(--bg-selected); */
    border-radius: 1rem;
    padding: 0.35rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    color:black;
    font-weight: 700;
    cursor: pointer;
    background: linear-gradient(to right, var(--color-blue-20) 50%, var(--bg-selected) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease;
}
.prediction-social-media__icon-share:hover .svg-icon{
    fill: white;
}
.prediction-social-media__icon-share:hover{
    /* background-color: var(--color-blue-20); */
    background-position: left bottom;
    color: white;
}
.prediction-social-media__icon-wrapper{
    width: 2.125rem;
    height: 2.125rem;
    box-sizing: border-box;
    padding: 0.5rem;
    border-radius: 50%;
    background: linear-gradient(to right, var(--color-blue-20) 50%, var(--bg-selected) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.prediction-social-media__icon-wrapper:hover{
       background-position: left bottom;
}
.prediction-social-media__icon-wrapper:hover .svg-icon{
    fill: white;
}

.tooltips-wrapper{
    background-color: white;
    padding: 0.25rem 0.5rem;
    position: absolute;
    border-radius: 0.25rem;
    top: -2rem;
    color: black;
    font-size: 0.625rem;

    transform: scale(0);
    transform-origin: 3rem 2rem;
    transition: all .3s;   
}
.prediction-social-media__icon-wrapper:hover .tooltips-wrapper{
    transform: scale(1);
}
.tooltips-wrapper.active{
    transform: scale(1);
}
.tooltips-triangle{
    position: absolute;
    top: 1rem;
    width: 0.7rem;
    height: 1rem;
    left: 44%;
    background: url(../../../static/images/tooltips_triangle_bottom.png) center/cover no-repeat;
}
.tooltips-content{
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}
.prediction-agree__title{
    flex: 1;
    font-size: 0.875rem;
    color: var(--color-grey-light)
}
</style>